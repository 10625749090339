import { RefObject, useEffect, useState } from 'react';
import { ONE_COLOR_SWATCH_WIDTH, TILE_PADDING_OFFSET } from '~/client/components/Gallery/DesignTile/constants';

export const useColorSwatchResizeObserve = (elementRef: RefObject<HTMLDivElement>): number => {
    const [maxElementsPerRow, setMaxElementsPerRow] = useState<number>(0);

    useEffect(() => {
        const currentElement = elementRef.current;

        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width } = entry.contentRect;
                const maxElements = Math.floor((width - TILE_PADDING_OFFSET) / ONE_COLOR_SWATCH_WIDTH);

                setMaxElementsPerRow(maxElements);
            }
        });

        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [elementRef, maxElementsPerRow]);

    return maxElementsPerRow;
};
