import { AlertBox, AlertBoxDismissButton } from '@vp/swan';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type DismissableAlertBoxProps = React.ComponentProps< typeof AlertBox> & {
    dismissVisuallyHiddenLabel: string;
    onRequestDismiss?: () => void
};

const DISMISS_ALERT_DELAY = 20000;

export const DismissableAlertBox = (props: DismissableAlertBoxProps): JSX.Element => {
    const {
        children, dismissed, dismissVisuallyHiddenLabel, onRequestDismiss, ...rest
    } = props;

    const debouncedDismissAlert = useDebouncedCallback(() => onRequestDismiss?.(), DISMISS_ALERT_DELAY);

    useEffect(() => {
        debouncedDismissAlert();
    }, [debouncedDismissAlert]);

    return (
        <AlertBox
            {...rest}
            dismissed={dismissed}
            onRequestDismiss={onRequestDismiss}
        >
            {children}
            <AlertBoxDismissButton accessibleText={dismissVisuallyHiddenLabel} />
        </AlertBox>
    );
};
