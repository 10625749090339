import {
    BrandContent, BrandData, Logo, LogoDetails,
} from '@vp/react-brand';
import { VistaAsset, VistaAssetStore } from '@design-stack-vista/vista-assets-sdk';

import type { DesignPersonalizationContext, DesignPersonalizationImage, DesignPersonalizationText } from '@vp/personalization-types';
import { PURPOSE_NAMES } from './constants';
import { TemplateField } from './purposeHelpers';
import { waitForDimensionData } from '../../Header/PersonalizationV1/utils';

export class FileTypeNotAllowedError extends Error {
    constructor(filetype = 'undefined') {
        super(`Cannot use ${filetype} in Design Personalization Context`);
        this.name = 'FileTypeNotAllowedError';
    }
}

export function getAssetIdsFromDpc(dpc: DesignPersonalizationContext): string[] {
    return dpc?.images?.map((i) => i.image.sherbertAssetId ?? '').filter((id) => id !== '') ?? [];
}

export const getTextFieldValuebyPurpose = (
    dpcText: DesignPersonalizationText[] | undefined,
    purposeName: PURPOSE_NAMES,
): (string | undefined

    ) => dpcText?.find((t) => t.textField.purposeName === purposeName)?.textField.text;

export const dpcToBrandContent = (dpc: DesignPersonalizationContext): BrandContent => {
    const brandContent: BrandContent = {};
    // For now, treat everything as facts.
    const facts: BrandData = {};

    const logos = dpc?.images?.filter((i) => i.purpose === 'logo');

    if (logos?.length) {
        facts.designIdentity = {
            ...facts?.designIdentity,
            logos: logos.map(({ image: { printUrl, previewUrl, originalSourceUrl } }) => ({
                uds: {
                    printUrl,
                    previewUrl,
                    originalSourceUrl,
                },
            })),
        };
    }

    const companyName = getTextFieldValuebyPurpose(dpc?.text, PURPOSE_NAMES.COMPANY_NAME);

    if (companyName) {
        facts.profile = {
            ...facts?.profile,
            companyName,
        };

        brandContent.displayName = companyName;
    }

    const fullName = getTextFieldValuebyPurpose(dpc?.text, PURPOSE_NAMES.FULL_NAME);

    if (fullName) {
        facts.people = [{ fullName }];
    }

    // no relevant data
    if (Object.keys(facts).length === 0) {
        return {};
    }

    brandContent.facts = facts;

    return brandContent;
};

export function dpcToTextFields(
    purposeNames: TemplateField[],
    dpc?: DesignPersonalizationContext,
): Record<string, string> {
    const matchedPurposes = purposeNames
        .map((p) => dpc?.text?.find((text) => p.purposeName === text.textField.purposeName));

    return matchedPurposes.reduce((result, dpcTextField) => {
        const purposeName = dpcTextField?.textField.purposeName;

        if (!purposeName) {
            return result;
        }

        return {
            ...result,
            [dpcTextField?.textField.purposeName]: dpcTextField?.textField.text,
        };
    }, {});
}

export const brandContentToDpc = async (
    brand: BrandContent | undefined,
    getLogoDetailsForBrand: (logoId: string) => Promise<LogoDetails>,
    fileExtensionAllowList: string[],
): Promise<DesignPersonalizationContext | undefined> => {
    if (!brand) {
        return undefined;
    }

    const fullName = brand.facts?.people?.[0]?.fullName || brand.speculations?.people?.[0]?.fullName || undefined;
    const companyName = brand.facts?.profile?.companyName || brand.speculations?.profile?.companyName || undefined;
    let logos = brand.facts?.designIdentity?.logos;

    if (logos?.length === 0) {
        logos = brand.speculations?.designIdentity?.logos;
    }

    if (!(fullName || companyName || logos?.length)) {
        return undefined;
    }

    const dpc: DesignPersonalizationContext = {
        designPersonalizationContextVersion: 0,
        text: [],
        colors: [],
        images: [],
    };

    const brandLogoToDpcImage = async (logo: Logo): Promise<DesignPersonalizationImage> => {
        const logoDetails = await getLogoDetailsForBrand(logo.logoId ?? '');

        if (!fileExtensionAllowList.includes(logoDetails.format?.toLowerCase() ?? '')) {
            throw new FileTypeNotAllowedError(logoDetails.format);
        }

        return {
            image: {
                // fallback to printUrl
                originalSourceUrl: logoDetails.originalSourceUrl ?? logoDetails.printUrl,
                previewUrl: logoDetails.previewUrl || '',
                printUrl: logoDetails.printUrl,
                width: logoDetails.dimensions?.width ?? 0,
                height: logoDetails.dimensions?.height ?? 0,
                sherbertAssetId: logo.assetId || '',
            },
            purpose: 'logo',
        };
    };

    if (logos && logos.length > 0) {
        dpc.images = await Promise.all(logos.map(
            (logo) => brandLogoToDpcImage(logo),
        ));
    }

    if (fullName) {
        dpc.text?.push({
            textField: {
                purposeName: PURPOSE_NAMES.FULL_NAME,
                text: fullName,
            },
        });
    }

    if (companyName) {
        dpc.text?.push({
            textField: {
                purposeName: PURPOSE_NAMES.COMPANY_NAME,
                text: companyName,
            },
        });
    }

    return dpc;
};

export function dpcToAppliedUploads(
    dpc?: DesignPersonalizationContext,
): Gallery.Models.Personalization.UploadIdentifier[] {
    return dpc?.images?.map((i) => ({
        assetId: i.image.sherbertAssetId ?? '',
        page: 1,
    })) ?? [];
}

/**
 * Pulls information from a VistaAsset to generate Design Personalization Image Data
 *
 * @param asset A VistaAsset to pull from
 * @param timeout The number of milliseconds to search before giving up when retrieving dimension data
 * @param defaultToPhoto Whether to assume an unclassified image is photo rather than a logo
 * @param pageNumber Which page of the VistaAsset to pull from
 * @returns { id: string; previewUrl: string; printUrl: string; thumbnailUrl: string; height: number; width: number; }
 */
export const convertVistaAssetToDpcImage = async (asset: VistaAsset, dimensionDataTimeout: number, defaultToPhoto: boolean, pageNumber?: number): Promise<DesignPersonalizationImage> => {
    const { height, width } = await waitForDimensionData(asset, pageNumber ?? 1, dimensionDataTimeout);
    const {
        data,
        print,
        webPreview,
    } = asset;

    let classifiedAsPhoto;

    // getImageInfo is the fast but less accurate way to get photo or logo classification
    // TODO: consider changing this https://vistaprint.atlassian.net/browse/QSP-779
    try {
        classifiedAsPhoto = data ? (await asset.getImageInfo?.(data?.id))?.isPhoto : defaultToPhoto;
    } catch (_error) {
        classifiedAsPhoto = defaultToPhoto;
    }
    const purpose = classifiedAsPhoto || defaultToPhoto ? 'photo' : 'logo';

    return {
        image: {
            originalSourceUrl: asset.getUrl({ includeSignature: true, pageNum: pageNumber ?? 1 }),
            previewUrl: webPreview.getUrl({ includeSignature: true, pageNum: pageNumber ?? 1 }),
            printUrl: print.getUrl({ includeSignature: true }),
            height: height ?? 0,
            width: width ?? 0,
            sherbertAssetId: data?.id ?? '',
        },
        purpose,
    };
};

/**
 * Pulls information from a VistaAsset identifed by an UploadIdentifier to generate Design Personalization Image Data
 *
 * @param upload The assetId and page to pull
 * @param timeout The number of milliseconds to search before giving up when retrieving dimension data
 * @param defaultToPhoto Whether to assume an unclassified image is photo rather than a logo
 * @param assetStore The asset store to fetch the VistaAsset from
 * @returns { id: string; previewUrl: string; printUrl: string; thumbnailUrl: string; height: number; width: number; }
 */
export async function selectedUploadToDpcImage(
    upload: Gallery.Models.Personalization.UploadIdentifier,
    dimensionDataTimeout: number,
    defaultToPhoto: boolean,
    assetStore?: VistaAssetStore,
): Promise<DesignPersonalizationImage | undefined> {
    if (!assetStore) {
        return undefined;
    }
    const { assetId, page } = upload;

    const asset = await assetStore.fetchSingleAsset({ id: assetId });

    await asset.presign();

    return convertVistaAssetToDpcImage(asset, dimensionDataTimeout, defaultToPhoto, page);
}

export const personalizedTextToDpcText = (
    personalizedText: Gallery.Models.Personalization.PersonalizedTextData,
): DesignPersonalizationText[] | undefined => {
    const dpcTextArray: DesignPersonalizationText[] = Object.keys(personalizedText)
        .filter((key) => personalizedText[key]) // remove undefined purposes
        .map((key) => ({
            textField: {
                purposeName: key,
                text: personalizedText[key],
            },
        }));

    return dpcTextArray.length > 0 ? dpcTextArray : undefined;
};
