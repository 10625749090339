import { memo, useMemo } from 'react';
import { TemplateTileColorSwatchWrapperProps } from '~/client/components/common/TemplateTile/components/TemplateTileColorSwatchWrapper/interface';
import { useI18nColorName } from '~/client/hooks/useI18nColorName';
import { getColorSwatchColors } from '~/client/utils/getColorSwatchColors';
import { TemplateTileColorSwatch } from '@vp/ep-template-tile';

export const TemplateTileColorSwatchWrapper = memo(({
    designId, locale, colorComposition, isColorSwatchColorsSimilar,
}: TemplateTileColorSwatchWrapperProps): JSX.Element => {
    const {
        primaryColor,
        secondaryColor,
    } = useMemo(
        () => getColorSwatchColors(colorComposition, isColorSwatchColorsSimilar),
        [colorComposition, isColorSwatchColorsSimilar],
    );

    const title = useI18nColorName(primaryColor, locale);

    return (
        <TemplateTileColorSwatch
            accessibleText={title}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            value={designId}
        />
    );
});
